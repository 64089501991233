/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { p, jsx } from 'theme-ui'

import LocationPreview from '../components/location-preview'
import CookieIcon from '../assets/icon/ic_cookie.svg'
import ShrimpIcon from '../assets/icon/ic_shrimp.svg'

const ReactMarkdown = require('react-markdown')

export default ({ location }) => (
  <>
    <Img
      sx={{
        borderRadius: `20px`,
      }}
      alt={location.name}
      fluid={location.heroImage.fluid}
    />
    <div
      sx={{
        '.information': {
          pt: 0,
          pb: 4,
        },
        '.rating': {
          display: `flex`,
          alignItems: `center`,
        },
        '.rating span': {
          mr: 2,
          fontWeight: `bold`,
        },
        '.rating img': {
          mr: 1,
        },
      }}
    >
      <div className="information">
        <header
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
            my: 3,
          }}
        >
          <div
            sx={{
              fontSize: 2,
            }}
          >
            <Link
              to={`/${location.country.slug}/${location.city.slug}/${location.slug}`}
            >
              {location.name}
            </Link>
          </div>

          <div className="rating">
            <img src={CookieIcon} alt="Cookie Rating" />
            <span>{location.cookieRating}</span>
            <img src={ShrimpIcon} alt="Tempura Rating" />
            <span>{location.tempuraRating}</span>
          </div>
        </header>
        <div>
          <p>
            {location.city.name}, {location.country.name}
          </p>
        </div>

        <ReactMarkdown
          source={location.description.childMarkdownRemark.rawMarkdownBody}
        />

        <div
          sx={{
            p: {
              fontSize: `0.852rem`,
              opacity: 0.6,
            },
          }}
          className="meta"
        >
          <p>{location.publishDate}</p>
        </div>
      </div>
    </div>
  </>
)
