/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { h2, jsx } from 'theme-ui'

import LocationPreview from './location-preview'

import CookieIcon from '../assets/icon/ic_cookie.svg'
import ShrimpIcon from '../assets/icon/ic_shrimp.svg'

export default ({ locations }) => (
  <>
    <section>
      <h4>Latest reviews</h4>
      <ul
        sx={{
          display: `grid`,
          gridTemplateColumns: `1fr 1fr 1fr`,
          gridColumnGap: `30px`,
          listStyleType: `none`,
          px: 0,
          my: 4,
        }}
      >
        {locations.map(({ node }) => {
          return (
            <li key={node.contentful_id}>
              <LocationPreview location={node} />
            </li>
          )
        })}
      </ul>
    </section>
  </>
)
