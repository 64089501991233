/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { h2, jsx } from 'theme-ui'

import ArticlePreview from './article-preview'

export default ({ articles }) => (
  <div>
    <h4>Latest articles</h4>
    <ul
      sx={{
        listStyleType: `none`,
        px: 0,
      }}
    >
      {articles.map(({ node }) => {
        return (
          <li
            sx={{
              display: `grid`,
              gridTemplateColumns: `1fr 3fr`,
              gridColumnGap: `20px`,
              px: 0,
              mb: 3,
            }}
            key={node.contentful_id}
          >
            <ArticlePreview article={node} />
          </li>
        )
      })}
    </ul>
  </div>
)
