/** @jsx jsx */
import React from 'react'
import Img from 'gatsby-image'

import { h3, jsx } from 'theme-ui'

import WidgetList from './widget-list'

export default ({ title, filter }) => (
  <div>
    <h5>Browse by {title}</h5>
    {filter.map(({ node }) => {
      return (
        <div key={node.contentful_id}>
          <WidgetList item={node} />
        </div>
      )
    })}
  </div>
)
