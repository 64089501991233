/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'

import { jsx } from 'theme-ui'

export default ({ item }) => (
  <ul
    sx={{
      listStyleType: `none`,
      p: 0,
    }}
  >
    <li>
      <a href={item.slug}>{item.name}</a>
    </li>
  </ul>
)
