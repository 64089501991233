/** @jsx jsx */

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { jsx } from 'theme-ui'

import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'

import Locations from '../components/location-container'
import Articles from '../components/article-container'
import Widget from '../components/widget-container'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    const articles = get(this, 'props.data.allContentfulBlogPost.edges')
    const locations = get(this, 'props.data.allContentfulLocation.edges')
    const countries = get(this, 'props.data.allContentfulCountry.edges')
    const cities = get(this, 'props.data.allContentfulCity.edges')
    const [startPage] = get(this, 'props.data.allContentfulPage.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
        <Hero data={startPage.node} />
        <Locations locations={locations} />

        <section>
          <div
            sx={{
              display: `grid`,
              gridTemplateColumns: `2fr 1fr`,
              gridColumnGap: `30px`,
              px: 0,
            }}
          >
            <Articles articles={articles} />
            <div>
              <Widget title="Country" filter={countries} />
              <Widget title="City" filter={cities} />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCountry(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          name
          contentful_id
          slug
        }
      }
    }
    allContentfulCity(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          name
          contentful_id
          slug
          country {
            name
            slug
          }
        }
      }
    }
    allContentfulLocation(
      limit: 6
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          name
          contentful_id
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          cookieRating
          tempuraRating
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          country {
            name
            slug
          }
          city {
            name
            slug
          }
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
    allContentfulPage(
      filter: { contentful_id: { eq: "15Hpo7A6NdaLIeMiQny0h2" } }
    ) {
      edges {
        node {
          title
          heroImage {
            fluid(maxWidth: 1180, maxHeight: 480, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
